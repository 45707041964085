.about-img {
    width: 100%;
}



@media only screen and (max-width: 600px) {
    .name {
        text-align: center;
    }
    .title {
        text-align: center;
    }
    .desc {
        padding: 5px;
        text-align: justify;
    }
    
  }