@import url("https://fonts.googleapis.com/css2?family=Bitter&display=swap");

.btn-maroon {
  color: #92140c;
  background-color: #ffffff;
  border: #92140c 1px solid;
  border-radius: 8px;
  padding: 12px;
  font-size: large;
}
.btn-maroon:hover {
  color: #ffffff;
  background-color: #92140c;
  border: #92140c 1px solid;
  border-radius: 8px;
  padding: 12px;
  font-size: large;
}
body {
  min-height: 100vh;
  font-family: "Raleway", Helvetica, Arial, Lucida, sans-serif;
}
.App {
  position: relative;
  padding-bottom: 12em;
  min-height: 100vh;  
}
h1 {
  font-family: "Bitter", serif;
}
h2 {
  font-family: "Bitter", serif;
}
h3 {
  font-family: "Bitter", serif;

  font-size: x-large;
}
.text-blue {
  color: #013D80;
}
.bg-blue {
  background-color: #1C5D82;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0; 
  right: 0;
}
.footer-links {
  text-decoration: none;
  color: #e9e9e9;
  transition: 500ms;
}
.footer-links:hover {
  color: #2a83b6;

  text-decoration: none;

}
.contact-text {
  color: #92140c;
  font-size: large;
  text-align: center;
}
.contact-img {
  width: 30%;
}
.location-img {
  object-fit: cover;
}

.inst-img {
  max-width: 90%;
}
.pointer {
  cursor: pointer;
}
.border-right {
  border-right: 1px solid rgb(199, 199, 199);
}
.bg-light-grey {
  background-color: lightgray;
}
.darker-row {
  background-color: rgba(92, 92, 92, 0.397);
}
.bg-hover-light-blue:hover {
 background-color: lightblue;
}