.active-account {
  background-color: rgba(22, 22, 214, 0.575);
}
.account-list-item:hover {
  cursor: pointer;
  background-color: rgba(16, 16, 201, 0.37);
}
.message-row {
    border: 1px solid rgb(153, 153, 153);
    
    padding: 1em;
}
.message-row-date {
    border-right: 1px solid black;

}
.account-manager-section {
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.messages-container {
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  border-top: 1px solid grey;
}